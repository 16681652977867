import React from 'react';

export default Home;

function Home() {
  // return <div>
    
  //         <main className="App-main">

  return  (
    <>
      <div className="App-main-container">
        <h2>反抗者</h2>

        <a className="App-link" href="https://zh.wikipedia.org/wiki/%E5%BD%AD%E7%AB%8B%E5%8F%91">四通桥勇士——彭载舟（彭立发）</a>

        <p>
            <a className="App-link" href="https://zh.wikipedia.org/wiki/%E7%B7%A8%E7%A8%8B%E9%9A%A8%E6%83%B3">编程随想——阮晓寰</a> <a className="App-link" href="https://program-think.blogspot.com">编程随想博客</a>
        </p>

        <a className="App-link" href="https://zh.wikipedia.org/wiki/%E7%99%BD%E7%B4%99%E9%81%8B%E5%8B%95">白纸革命——各地反抗共匪的民众</a>

      </div>

            <div className="App-main-container">
              <h2>民的国</h2>

              <a className="App-link" href="https://zh.wikipedia.org/wiki/%E6%AD%A6%E6%98%8C%E8%B5%B7%E4%B9%89">辛亥革命——武昌首义</a>

              <p>
                <a className="App-link" href="https://zh.wikipedia.org/wiki/%E8%BE%9B%E4%BA%A5%E9%9D%A9%E5%91%BD">辛亥革命</a>
              </p>

              <a className="App-link" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E8%8F%AF%E6%B0%91%E5%9C%8B">中华民国</a>

            </div>

            <div className="App-main-container">
               <h2>地理人文</h2>
               <a className="App-link" href="https://zh.wikipedia.org/wiki/%E6%AD%A6%E6%98%8C%E5%8C%BA">武昌</a>

               <p>
                 <a className="App-link" href="https://zh.wikipedia.org/wiki/%E6%B1%89%E5%8F%A3">汉口</a>
               </p>

               <a className="App-link" href="https://zh.wikipedia.org/wiki/%E6%B1%89%E9%98%B3%E5%8C%BA">汉阳</a>

               <p>
                 <a className="App-link" href="https://zh.wikipedia.org/wiki/%E6%AD%A6%E6%B1%89%E5%B8%82">武汉</a>
               </p>

               <a className="App-link" href="https://zh.wikipedia.org/wiki/%E6%B9%96%E5%8C%97%E7%9C%81">湖北</a>

               <p>
                 <a className="App-link" href="https://zh.wikipedia.org/wiki/%E6%A5%9A%E5%9B%BD">楚国</a>
               </p>

               <a className="App-link" href="https://zh.wikipedia.org/wiki/%E4%B8%AD%E8%8F%AF%E6%B0%91%E5%9C%8B">中华民国</a>
             </div>

             <div className="App-main-container">
               <h2>信息/技术</h2>

               <a className="App-link" href="https://greatfire.org">Greatfire.org</a>

               <p>
                 <a className="App-link" href="https://chinadigitaltimes.net">中国数字时代</a>
               </p>

             </div>

      <div clasName="App-main-container">
        
        <p>
          提示：大部分外部链接的资源都被共匪封锁，匪国内用户需要翻墙访问。本网站内容会自行配备翻墙功能，但并不保证稳定。
        </p>

      </div>

    </>
  );

           // </main>
    
         // </div>;
}
