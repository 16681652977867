import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import Home from "./Home"
import ForumJT from "./ForumJT"

function App() {

  return (
    <div className="App">
      
      <Router>

        <header className="App-header">

          {/* <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}

          <h1>叛都1911 — Capital 1911</h1>
          <p>让我们从虚拟世界开始，重现那个诞生“民的国”的叛都。</p>

        </header>
        
        <nav>
          <Link to="/">主页</Link>
          <Link to="/forum-jt">江滩 — 论坛</Link>
        </nav>

        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/forum-jt" element={<ForumJT />} />
          </Routes>
        </main>

        <footer className="App-footer">

          <p>
            <a className="App-footer-link" href="https://walker1911.me">行者1911 — Walker 1911</a>&nbsp;&nbsp;
            <a className="App-footer-link" href="https://capital1911.org">叛都1911 — Capital 1911</a>
          </p>

          <p>
            © 2024 叛都1911 — Capital 1911. All rights reserved.
          </p>

        </footer>
        
      </Router>

    </div>
  );
  
  return (
    <div className="App">

    </div>
  );
}

export default App;
