import React from "react";

export default ForumJT;

function ForumJT() {
  return (
    <>
      <div className="App-main-container">
        
        <a
          className="App-link"
          href="https://groups.google.com/g/capital1911"
          style={{ fontSize: "2em" }}
        >
          江滩 - 叛都1911论坛
        </a>
  
        <p>
          提示：本论坛目前架设在 Google Groups 中，属于本网站的外部资源，匪国内用户需要翻墙访问。
        </p>

      </div>
      
    </>
  );
}
